import cn from 'classnames'
import { Button } from 'components/buttons'
import { InputFile } from 'components/formParts'
import { InnerPage } from 'components/layout'
import { get } from 'lodash'
import { func, shape } from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { checkIfFileIsCsv } from 'utils/files'

import st from './MassUpdater.module.css'
import { massUpdateMw } from './mw'

const MassUpdater = ({ dispatch, intl }) => {
  const [file, setFile] = useState(null)
  const [error, setError] = useState('')
  const fileName = get(file, 'name', '')
  const [results, setResults] = useState(null)

  const handleFileChange = (e) => {
    const fileObj = get(e, 'target.files[0]')
    const isCSV = checkIfFileIsCsv(fileObj)
    if (isCSV) {
      setFile(fileObj)
      setError('')
      setResults(null)
      e.target.value = '' // clear input value to avoid issues with same file selection
    } else {
      const errorMsg = intl.formatMessage({
        id: 'Upload only CSV files please'
      })

      setError(errorMsg)
      setFile(null)
      setResults(null)
    }
  }

  const handleFileClose = () => {
    setError('')
    setFile(null)
    setResults(null)
  }

  const handleUpload = () => {
    if (file) {
      massUpdateMw({
        dispatchRedux: dispatch,
        file,
        onSuccess: (response) => setResults(response)
      })
    }
  }

  return (
    <InnerPage heading={intl.formatMessage({ id: 'Mass Updater' })}>
      {results && (
        <div className={st.results}>
          <div className={st.summary}>
            <span>
              {intl.formatMessage({ id: 'Successfully updated' })}:{' '}
              {results.succefully_updated}
            </span>
            <span>
              {intl.formatMessage({ id: 'Not modified' })}:{' '}
              {results.not_modified}
            </span>
          </div>
          {results.data.map((item) => {
            const isSuccess = item.success
            const isError = !item.success

            return (
              <div
                className={cn({
                  [st.success]: isSuccess,
                  [st.error]: isError
                })}
                key={item.sku}
              >
                {item.sku}:{' '}
                {intl.formatMessage({
                  id: isSuccess ? 'Successfully updated' : 'Failed'
                })}
                .{' '}
                {item.error && (
                  <>
                    {intl.formatMessage({ id: 'Error' })}: {item.error}
                  </>
                )}
              </div>
            )
          })}
        </div>
      )}

      <InputFile
        allowDeleting={Boolean(file || error)}
        accept="text/csv"
        className={st.control}
        error={error}
        height={200}
        label={
          fileName ||
          intl.formatMessage({
            id: 'No file uploaded yet'
          })
        }
        subLabel={intl.formatMessage({ id: 'CSV files only' })}
        title={intl.formatMessage({
          id: 'Upload a CSV file in order to update many products'
        })}
        width="100%"
        wrapClassName={st.input}
        onChange={handleFileChange}
        onClose={handleFileClose}
      />
      <div className={st.buttons}>
        <Button disabled={!file} onClick={handleUpload}>
          <FormattedMessage id="Upload" />
        </Button>
      </div>
    </InnerPage>
  )
}

MassUpdater.propTypes = {
  intl: shape({
    formatMessage: func
  }),
  dispatch: func
}

export default connect()(injectIntl(MassUpdater))
