import { PageNav } from 'components'
import { InnerPage } from 'components/layout'
import { FloatList } from 'components/lists'
import { func, node, number, shape, string } from 'prop-types'
import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getUserType } from 'utils/auth'
import { USER_TYPES } from 'utils/constants'
import { vendorNavItems } from 'utils/utils'

import { mapActions, mapState, topMenuItems } from './config'
import st from './VendorAnalyticsLayout.module.css'

const VendorAnalyticsLayout = ({
  GetCollection,
  children,
  currentURL,
  intl: { formatMessage },
  timeFrame,
  setTimeFrame,
  vendorId,
  authReducer,
  intl,
  vendorName
}) => {
  const tmItems = topMenuItems({
    currentURL,
    vendorId
  })

  const userType = getUserType(authReducer)

  useEffect(() => {
    GetCollection(vendorId)
  }, [vendorId])

  return (
    <InnerPage
      heading={
        userType === USER_TYPES.VENDOR
          ? formatMessage({ id: 'Analytics Dashboard' })
          : vendorName
      }
    >
      {userType !== USER_TYPES.VENDOR && (
        <PageNav
          className={st.pageNav}
          items={vendorNavItems({
            id: vendorId,
            intl,
            path: currentURL,
            userType: getUserType(authReducer)
          })}
        />
      )}
      <div className={st.topMenuCnt}>
        <FloatList
          className={st.topMenu}
          activeClassName={st.active}
          items={tmItems}
          mod={1}
        />
        <p className={st.bottomLine}>
          {formatMessage({ id: 'Reporting timeframe' })}{' '}
          {setTimeFrame ? (
            <select
              defaultValue={timeFrame}
              onChange={({ target }) => {
                setTimeFrame(+target.value)
              }}
            >
              {[7, 30, 60, 90, 365].map((days) => (
                <option value={days} key={days}>
                  {formatMessage(
                    { id: 'Reporting timeframe days' },
                    { value: days }
                  )}
                </option>
              ))}
            </select>
          ) : (
            formatMessage(
              { id: 'Reporting timeframe days' },
              { value: <b>{timeFrame}</b> }
            )
          )}
        </p>
      </div>
      {children}
    </InnerPage>
  )
}

VendorAnalyticsLayout.defaultProps = {
  children: null,
  currentURL: '',
  timeFrame: 0,
  setTimeFrame: null,
  vendorId: ''
}
VendorAnalyticsLayout.propTypes = {
  collectionId: string,
  GetCollection: func,
  children: node,
  currentURL: string,
  timeFrame: number,
  setTimeFrame: func,
  vendorId: string,
  intl: shape({
    formatMessage: func
  }),
  authReducer: shape({}),
  vendorName: string
}

export default connect(mapState, mapActions)(injectIntl(VendorAnalyticsLayout))
