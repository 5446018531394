import cf from 'currency-formatter'
import { get, keys, values } from 'lodash'
import { USER_TYPES } from 'utils/constants'

/**
 * @param {Object} obj
 * @returns {Boolean}
 */
export const areAllObjPropsEmpty = (obj = {}) => {
  const objValues = values(obj)

  return objValues.filter((ov) => !!ov).length === 0
}

export const clearPathFromQuery = (path = '') => {
  const questIdx = path.indexOf('?')

  return questIdx < 0 ? path : path.slice(0, questIdx)
}

export const createArray = (page, pagesLength) => {
  let renderArray = [page - 2, page - 1, page, page + 1, page + 2]

  if (page === pagesLength && page >= 5)
    renderArray = [page - 4, page - 3, page - 2, page - 1, page]

  if (page === pagesLength - 1 && page >= 4)
    renderArray = [page - 3, page - 2, page - 1, page, page + 1]

  if (page === 1 || page === 2 || page === 3 || page === 4)
    renderArray = Array.from(
      { length: Math.min(pagesLength, 5) },
      (x, i) => i + 1
    )

  return renderArray
}

export const escape = (str = '') => {
  const chars = [{ char: ':', esc: '' }]

  return chars.reduce((acc, { char, esc }) => {
    return acc.split(char).join(esc)
  }, str)
}

/**
 * @param {Object} config
 * @param {Array} config.arr - array to filter
 * @param {Array} config.filters
 * @param {Function} config.filters[]
 */
export const filterByProps = ({ arr = [], filters = [] } = {}) => {
  return filters.reduce((res, filterFunc) => {
    const newRes = res.filter(filterFunc)

    return newRes
  }, arr)
}

/**
 * @param {Object} config
 * @param {String} config.currency
 * @param {Number} config.price
 */
export const formatPrice = (config = {}) => {
  const { currency, price } = config
  const { symbol } = cf.findCurrency(currency) || {}

  return cf.format(price, {
    decimal: ',',
    symbol,
    thousand: '.'
  })
}

/**
 * @param {Object} vendorReducer
 * @param {Object} vendorReducer.vendor
 * @param {String} vendorReducer.vendor.artisanName
 * @param {String} vendorReducer.vendor.name
 */
export const getVendorName = (vendorReducer) => {
  const artisanName = get(vendorReducer, 'vendor.artisanName', '')
  const name = get(vendorReducer, 'vendor.name', '')

  return artisanName || name
}

/**
 * @param {Number} gutter
 */
export const gutterStyles = (gutter = 30) => {
  const oneSideValue = Math.abs(gutter) / 2

  return {
    col: {
      paddingLeft: oneSideValue,
      paddingRight: oneSideValue
    },
    row: {
      marginLeft: -oneSideValue,
      marginRight: -oneSideValue
    }
  }
}

/**
 * @param {Object} obj
 */
export const keepFullObjProps = (obj = {}) => {
  const objKeys = keys(obj)

  return objKeys.reduce((acc, objKey) => {
    const objValue = obj[objKey]

    if (objValue) {
      acc[objKey] = objValue
    }

    return acc
  }, {})
}

/**
 * @param {Number} num
 * @param {Number} lastFigures
 */
export const parseNumber = (num = 0, lastFigures = 2) => {
  return parseFloat(num).toFixed(lastFigures)
}

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(window.atob(base64))
}

export const isExpired = (exp) => Date.now() >= exp * 1000

/**
 * @param {Object} props
 * @param {Object} props.location
 * @param {String} props.location.pathname
 */
export const pathName = (props = {}) => get(props, 'location.pathname', '')

/**
 * @param {String} word
 * @param {Number} count
 */
export const pluralWord = (word = '', count = 1) => {
  const wEnd = count === 1 ? '' : 's'

  return word + wEnd
}

export const sortVendorsByDate = (a, b) => {
  const timeA = get(a, 'profile.addDate')
  const timeB = get(b, 'profile.addDate')

  return timeA > timeB ? -1 : 1
}

/**
 * @param {Object} data
 * @param {Boolean} data.isOn
 * @param {Object} data.obj
 * @param {String} data.propName
 */
export const switchObjProps = ({ isOn = false, obj = {}, propName = '' }) => {
  const newObj = { ...obj }

  if (typeof propName !== 'string' || !propName.length) {
    return
  }

  if (isOn) {
    newObj[propName] = Boolean(isOn)
  } else {
    delete newObj[propName]
  }

  return newObj
}

/**
 *
 * @param {String} url
 * @returns {String} //file extension
 */
export function getURLExtension(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

/**
 * @param {String} collectionId
 * @param {String} id - vendor ID
 * @param {Object} intl
 * @param {Function} intl.formatMessage
 * @param {String} intl.path
 * @param {String} userType
 */
export const vendorNavItems = ({
  id = '',
  intl = {},
  path = '',
  userType = 'manager'
}) => {
  if (!id) {
    return []
  }

  let items =
    userType === USER_TYPES.APPROVER || userType === USER_TYPES.VENDOR
      ? []
      : [
          {
            label: intl.formatMessage({ id: 'Vendor Summary' }),
            path: `/onboarding/${id}`
          },
          {
            label: intl.formatMessage({ id: 'Premium Management' }),
            path: `/vendor-subscription/${id}`
          }
        ]

  items = items.concat([
    {
      label: intl.formatMessage({ id: 'Contact Info' }),
      path: `/vendor-contact/${id}`
    },
    {
      label: intl.formatMessage({ id: 'Collection List' }),
      path: `/vendors/${id}/collections`,
      onClick: () => {
        window.location.href = `/vendors/${id}/collections`
      }
    }
  ])

  const allowBtns =
    userType === USER_TYPES.ADMIN ||
    userType === USER_TYPES.APPROVER ||
    userType === USER_TYPES.BUYER ||
    userType === USER_TYPES.MANAGER ||
    userType === USER_TYPES.LOGISTICS

  if (allowBtns) {
    items = items.concat([
      {
        label: intl.formatMessage({ id: 'Bio' }),
        path: `/vendor-bio/${id}`
      },
      {
        label: intl.formatMessage({ id: 'Orders' }),
        path: `/order-list/${id}`
      },
      {
        label: intl.formatMessage({ id: 'Catalog' }),
        path: `/vendors/${id}/catalog`,
        onClick: () => {
          window.location.href = `/vendors/${id}/catalog`
        }
      },
      {
        label: intl.formatMessage({ id: 'Analytics Dashboard' }),
        path: `/vendor/${id}/analytics`
      }
    ])
  }

  return items.map((item) => ({
    ...item,
    current: item.path.includes('analytics')
      ? path.startsWith(item.path)
      : item.path === path
  }))
}

export const extractProductFromSettledPromises = (settledPromises) => {
  return settledPromises
    .filter((promiseResult) => promiseResult.status === 'fulfilled')
    .map((fullfilledPromise) => fullfilledPromise.value)
}
