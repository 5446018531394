import axios from 'axios/index'
import cookie from 'react-cookies'
import { ORDER_BY, ORDER_PARAM } from 'utils/constants'
import { mapError } from 'utils/errors'

import apiUrl from './apiUrl'

/**
 * @param {Object} params
 * @param {Boolean} params.allReviewed
 * @param {Boolean} params.approved
 * @param {String} params.artisan
 * @param {Number} params.limit
 * @param {String} params.order
 * @param {Number} params.page
 * @param {String} params.sort
 */
const read = async (params = {}) => {
  const apiPath = `${apiUrl}/api/collection`
  const { allReviewed, ...restParams } = params

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') },
      params: {
        ...restParams,
        all_reviewed: allReviewed
      }
    })

    if (status === 200) {
      return data
    }

    throw new Error('Bad collections fetch')
  } catch (error) {
    return { error: mapError(error) }
  }
}

const add = (collection) =>
  axios.post(`${apiUrl}/api/collection`, collection, {
    headers: { token: cookie.load('artemest') }
  })

const getCollectionByVendor = (vendor, page = 1, limit = 50) =>
  axios.get(`${apiUrl}/api/collection/list-from-vendor/${vendor}`, {
    params: { page, limit },
    headers: { token: cookie.load('artemest') }
  })

const list = (vendor) =>
  axios.get(`${apiUrl}/api/collection/list-selected-name/${vendor}`, {
    headers: { token: cookie.load('artemest') }
  })

const readCollection = async (collectionId = '') => {
  const apiPath = `${apiUrl}/api/collection/${collectionId}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} params
 * @param {String} [params.collection]
 * @param {Boolean} [params.hasProducts]
 * @param {String} [params.order]
 * @param {Number} [params.page]
 * @param {Boolean} [params.readyToTranslate]
 * @param {String} [params.sort]
 * @param {String} [params.vendor]
 */
const getCollectionProductTranslates = async (params = {}) => {
  const {
    collection = '',
    hasProducts = '',
    order = ORDER_PARAM.ADD_DATE,
    page = 1,
    readyToTranslate = '',
    sort = ORDER_BY.DESC,
    vendor = null,
    isApproved = true
  } = params
  const apiPath = `${apiUrl}/api/collection/list/translations`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') },
      params: {
        collection,
        hasProducts,
        order,
        page,
        readyToTranslate,
        sort,
        isApproved,
        ...(vendor && { vendor })
      }
    })

    if (status === 200) {
      return { ...data }
    }

    throw new Error(`Bad response status: ${status}`)
  } catch (error) {
    return { error }
  }
}

const approve = (id) =>
  axios.put(
    `${apiUrl}/api/collection/approve/${id}`,
    {},
    {
      headers: { token: cookie.load('artemest') }
    }
  )

const disApprove = (id) =>
  axios.put(
    `${apiUrl}/api/collection/disapprove/${id}`,
    {},
    {
      headers: { token: cookie.load('artemest') }
    }
  )

const remove = (id) =>
  axios.delete(`${apiUrl}/api/collection/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

const uploadFile = (bodyFormData, dataTypesId, collectionId) =>
  axios({
    url: `${apiUrl}/api/collection/image/${dataTypesId}/${collectionId}`,
    method: 'post',
    data: bodyFormData,
    headers: { token: cookie.load('artemest') }
  })

const removeFile = (id) =>
  axios.delete(`${apiUrl}/api/collection/delete-image/${id}`, {
    headers: { token: cookie.load('artemest') }
  })

const updateName = (id, name) =>
  axios.put(
    `${apiUrl}/api/collection/change-name/${id}/${name}`,
    {},
    {
      headers: { token: cookie.load('artemest') }
    }
  )

const downloadCSV = (collection) =>
  axios.get(`${apiUrl}/api/product/generate-csv/${collection}`, {
    headers: { token: cookie.load('artemest') }
  })

/**
 * @param {Object} config
 * @param {String} config.collectionId
 * @param {Number} config.page
 * @param {Number} config.perPage
 * @param {String} config.productStatus
 * @param {Boolean|String} config.ready
 * @param {String} config.sortBy
 * @param {String} config.sortOrder
 */
const getAllTranslations = async (config = {}) => {
  const {
    collectionId = '',
    page = 1,
    perPage = 10,
    productStatus = '',
    ready = '',
    sortBy = '',
    sortOrder = ''
  } = config
  const apiPath = `${apiUrl}/api/translation/collection/${collectionId}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') },
      params: {
        order: sortOrder,
        page,
        perPage,
        productStatus,
        ready,
        sort: sortBy
      }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.vendorId
 */
const getCompanyBioByVendorId = async (config = {}) => {
  const { vendorId } = config
  const apiPath = `${apiUrl}/api/collection/company-bio/${vendorId}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

export {
  add,
  approve,
  disApprove,
  downloadCSV,
  getAllTranslations,
  getCollectionByVendor,
  getCollectionProductTranslates,
  getCompanyBioByVendorId,
  list,
  read,
  readCollection,
  remove,
  removeFile,
  updateName,
  uploadFile
}
