import './styles/sheared.css'

import cn from 'classnames'
import { PageNav } from 'components'
import { Button } from 'components/buttons'
import { Input as CustomInput, InputFile } from 'components/formParts'
import { InnerPage } from 'components/layout'
import { Modal } from 'components/modals'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Formik } from 'formik'
import { get } from 'lodash'
import msg from 'messages'
import {
  array,
  arrayOf,
  bool,
  func,
  oneOfType,
  shape,
  string
} from 'prop-types'
import React, { Component, Fragment, useEffect, useState } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { MdDelete } from 'react-icons/md'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Input } from 'reactstrap'
import { GetCollection } from 'redux/actions/collection-action'
import {
  AddVendor,
  GetVendor,
  getVendor,
  UpdateVendor
} from 'redux/actions/vendor-action'
import { activate, removeUser } from 'redux/service/user-service'
import { deleteAttachment, saveAttachement } from 'redux/service/vendor-service'
import slugify from 'slugify'
import urlRegex from 'url-regex'
import { getUserType, isAdmin, isManager } from 'utils/auth'
import {
  COUNTRIES_LIST,
  CURRENCIES_LIST,
  DEFAULT_COUNTRY
} from 'utils/constants'
import notify, { notifyPositions, showNotify } from 'utils/toast'
import { vendorNavItems } from 'utils/utils'
import { FISCAL_REGIMEN } from 'utils/vendors'
import * as Yup from 'yup'

import Footer from '../components/footer/footer'
import Loading from '../components/loading/loading'
import Style from './styles/onboarding.module.css'

dayjs.extend(customParseFormat)

const YesNoRadioButtons = ({
  name,
  value,
  setFieldValue,
  handleBlur,
  options,
  requiredForPublish
}) => {
  return (
    <div className={Style.inputWrap}>
      <label className={Style.label}>
        <FormattedMessage id={name} />
        {requiredForPublish && ' *'}
      </label>

      <div className={cn(Style.sameLineCheckboxs, Style.checkboxs)}>
        {options ? (
          options.map((option) => {
            return (
              <div className="custom-control custom-radio" key={option}>
                <input
                  type="radio"
                  id={`${name}-${option}`}
                  name={name}
                  value={option}
                  checked={value === undefined ? false : value === option}
                  onChange={() => setFieldValue(name, option)}
                  onBlur={handleBlur}
                  className="custom-control-input"
                />

                <label
                  className="custom-control-label"
                  htmlFor={`${name}-${option}`}
                >
                  <FormattedMessage id={option} />
                </label>
              </div>
            )
          })
        ) : (
          <>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id={`${name}-yes`}
                name={name}
                value="true"
                checked={value === undefined ? false : value}
                onChange={() => setFieldValue(name, true)}
                onBlur={handleBlur}
                className="custom-control-input"
              />

              <label className="custom-control-label" htmlFor={`${name}-yes`}>
                <FormattedMessage id="yes" />
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id={`${name}-no`}
                name={name}
                value="false"
                checked={value === undefined ? false : !value}
                onChange={() => setFieldValue(name, false)}
                onBlur={handleBlur}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor={`${name}-no`}>
                No
              </label>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const UploadElement = ({
  fieldValues,
  fieldLabel,
  fieldPlaceholder,
  fieldName,
  setFieldValue,
  vendorCode,
  requiredForPublish
}) => {
  const [loading, setLoading] = useState(false)
  const [fileDelete, setFileDelete] = useState('')

  const handleFileUpload = async (e, type, setFieldValue) => {
    setLoading(true)
    let { files } = e.target

    if (files.length) {
      const response = await saveAttachement(vendorCode, type, files)
      const { error, uploadedFiles } = response

      if (error) {
        showNotify({
          isError: true,
          message: `Unable to upload ${type}${files.length > 1 ? 's' : ''}: ${
            error.data
          }`
        })
      }
      if (uploadedFiles) {
        setFieldValue(type, [...uploadedFiles])
        showNotify({
          message: `${
            files.length === 1 ? 'File' : 'Files'
          } uploaded successfully!`
        })
      }
      if (response) {
        setLoading(false)
      }
    } else {
      showNotify({ message: 'Something went wrong, please try again' })
      setLoading(false)
    }
  }

  const handleFileDelete = async (file, type) => {
    if (file) {
      const response = await deleteAttachment(vendorCode, type, file)
      const { error } = response

      if (error) {
        showNotify({
          isError: true,
          message: 'Unable to delete file'
        })
      }

      if (response[type]) {
        setFieldValue(type, response[type])
        showNotify({
          message: 'File deleted successfully'
        })
      }
      closeConfirmModal()
    }
  }

  const closeConfirmModal = () => {
    setFileDelete('')
  }

  return (
    <>
      <div className={Style.uploadWrap}>
        <label htmlFor={fieldName}>
          {`${fieldLabel} ${requiredForPublish ? '*' : ''}`}
        </label>
        <div>
          <ul>
            {fieldValues.map((file) => (
              <li key={file}>
                <a href={file} rel="noopener noreferrer" target="_blank">
                  {file.split('/')[file.split('/').length - 1]}
                </a>
                <button
                  title="Delete"
                  type="button"
                  onClick={() => {
                    setFileDelete(file)
                  }}
                >
                  <MdDelete />
                </button>
              </li>
            ))}
          </ul>
          <InputFile
            multiple
            size={120}
            onChange={(e) => {
              handleFileUpload(e, fieldName, setFieldValue)
            }}
            label={fieldPlaceholder}
            isLoading={loading}
          />
        </div>
      </div>
      <Modal
        title={
          <>
            <FormattedMessage id="Delete" /> {fieldLabel.toLowerCase()}
          </>
        }
        isOpen={fileDelete !== ''}
        toggle={closeConfirmModal}
        body={<FormattedMessage id="deleteFileConfirm" />}
        footer={
          <div>
            <Button
              style={{ marginRight: '20px' }}
              onClick={() => {
                handleFileDelete(
                  fileDelete,
                  fieldName,
                  setFieldValue,
                  fieldValues
                )
              }}
            >
              <FormattedMessage id="Confirm" />
            </Button>
            <Button onClick={closeConfirmModal}>
              <FormattedMessage id="Cancel" />
            </Button>
          </div>
        }
      />
    </>
  )
}

class Onboarding extends Component {
  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id: idPrev }
      }
    } = prevProps
    const {
      getVendor,
      match: {
        params: { id }
      },
      vendorReducer: { vendor }
    } = this.props

    if (!id && vendor) {
      getVendor(null)
    }

    if (idPrev !== id && id) {
      this.init()
    }
  }

  getId = () => get(this.props, 'match.params.id', '')
  getCollectionId = () => {
    const {
      collectionReducer: { collections }
    } = this.props

    return get(collections, '[0]._id', '')
  }

  init() {
    const {
      GetCollection,
      getVendor,
      GetVendor,
      vendorReducer: { vendor }
    } = this.props
    const id = this.getId()

    if (id) {
      GetCollection(id)
      GetVendor({ vendorId: id })
    } else if (vendor) {
      getVendor(null)
    }
  }

  getInitValues = () => {
    const {
      vendorReducer: { vendor }
    } = this.props

    return {
      area: get(vendor, 'area', ''),
      artisanName: get(vendor, 'artisanName', ''),
      slug: get(vendor, 'slug', ''),
      contractPenalties: get(vendor, 'contractPenalties', undefined),
      country: get(vendor, 'country', DEFAULT_COUNTRY),
      contractType: get(vendor, 'contractType', ''),
      discountPercentage: get(vendor, 'discountPercentage', undefined),
      discountNote: get(vendor, 'discountNote', undefined),
      downPaymentWhenReceivedOrder:
        get(vendor, 'downPaymentWhenReceivedOrder', undefined) || undefined,
      excludedCountries: get(vendor, 'excludedCountries', []),
      fiscalRegimen: get(vendor, 'fiscalRegimen', ''),
      fixedPriceList: get(vendor, 'fixedPriceList', []),
      fullPaymentBeforeShipping: get(
        vendor,
        'fullPaymentBeforeShipping',
        undefined
      ),
      otherSites: get(vendor, 'otherSites', ''),
      packagePicsBeforeShipping: get(
        vendor,
        'packagePicsBeforeShipping',
        undefined
      ),
      productPicsBeforeShipping: get(
        vendor,
        'productPicsBeforeShipping',
        undefined
      ),
      email: get(vendor, 'email', ''),
      description: get(vendor, 'description', ''),
      website: get(vendor, 'website', ''),
      state: get(vendor, 'state', ''),
      bespokeAvailable: get(vendor, 'bespokeAvailable', undefined),
      vetted: get(vendor, 'vetted', undefined),
      returnAllowed: get(vendor, 'returnAllowed', undefined),
      fromArtisanToClient: get(vendor, 'fromArtisanToClient', undefined),
      buyer: get(vendor, 'buyer', ''),
      contracts: get(vendor, 'contracts', []),
      catalogs: get(vendor, 'catalogs', [])
    }
  }

  getVendorName = () => {
    const {
      vendorReducer: { vendor }
    } = this.props
    const artisanName = get(vendor, 'artisanName', '')
    const name = get(vendor, 'name', '')

    return artisanName || name
  }

  getVendorLastEdit = () => {
    const {
      vendorReducer: { vendor }
    } = this.props

    if (!vendor?.lastEdit) {
      return undefined
    }

    const date = new Date(vendor.lastEdit.date)

    return {
      date: [
        date.getDate().toString().padStart(2, '0'),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getFullYear()
      ].join('/'),
      editor: vendor.lastEdit.editor
    }
  }

  getVendorLastPublish = () => {
    const {
      vendorReducer: { vendor }
    } = this.props

    if (!vendor?.lastPublish) {
      return undefined
    }

    const date = new Date(vendor.lastPublish.date)

    return {
      date: [
        date.getDate().toString().padStart(2, '0'),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getFullYear()
      ].join('/')
    }
  }

  parseEditedFieldsValue = (value) => {
    return typeof value === 'boolean' ? (value ? 'Sì' : 'No') : value
  }

  handleArtisanSubmit = (values) => {
    const {
      canUpdate,
      history: { push }
    } = this.props

    const alertOnEditFields = [
      'downPaymentWhenReceivedOrder',
      'fiscalRegimen',
      'fullPaymentBeforeShipping'
    ]

    if (!canUpdate) {
      return
    }

    const id = this.getId()
    const data = { ...values }
    const initialValues = this.getInitValues()
    const editedFields = alertOnEditFields.reduce((edited, key) => {
      const oldValue = initialValues[key]
      const newValue = data[key]

      if (newValue !== oldValue) {
        edited.push({
          fieldName: msg.it[key], // always italian for email purposes
          oldValue: this.parseEditedFieldsValue(oldValue),
          newValue: this.parseEditedFieldsValue(newValue)
        })
      }

      return edited
    }, [])

    if (id) {
      this.props.UpdateVendor({
        data,
        editedFields,
        id,
        onSuccess: () => {
          // push('/onboarding/' + id)
        }
      })
    } else {
      this.props.AddVendor({
        data,
        onSuccess: (newId) => {
          push('/onboarding/' + newId)
        }
      })
    }
  }

  activateCreditials = () => {
    const vendorId = this.getId()

    if (vendorId) {
      activate(vendorId)
        .then(() => {
          notify(
            'success',
            'activated successfully',
            notifyPositions.bottom.center
          )
        })
        .catch((onerror) => {
          const { message, response } = onerror

          notify(
            'error',
            get(response, 'data', '') || message,
            notifyPositions.bottom.center
          )
        })
    }
  }

  disableCredentials = () => {
    const vendorId = this.getId()

    if (vendorId) {
      removeUser(vendorId)
        .then(() => {
          notify(
            'success',
            'deactivated/removed user successfully',
            notifyPositions.bottom.center
          )
        })
        .catch((onerror) => {
          notify('error', onerror.message, notifyPositions.bottom.center)
        })
    }
  }

  render() {
    const {
      canUpdate,
      intl,
      location: { pathname },
      userType
    } = this.props
    const buttonMessage = this.getId() ? 'Update' : 'Submit'
    const navItems = vendorNavItems({
      id: this.getId(),
      intl,
      path: pathname,
      userType
    })
    const webUrlReg = urlRegex({
      exact: true,
      strict: false
    })
    const schema = Yup.object().shape({
      area: Yup.string()
        .min(
          2,
          intl.formatMessage({
            id: 'Please enter more than 2 characters'
          })
        )
        .required(
          intl.formatMessage({
            id: 'Please enter City'
          })
        ),
      artisanName: Yup.string()
        .min(
          2,
          intl.formatMessage({
            id: 'Please enter more than 2 characters'
          })
        )
        .required(
          intl.formatMessage({
            id: 'Please enter Artisan name'
          })
        ),
      slug: Yup.string()
        .min(
          2,
          intl.formatMessage({
            id: 'Please enter more than 2 characters'
          })
        )
        .required(
          intl.formatMessage({
            id: 'Please enter Slug'
          })
        )
        .matches(
          /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
          intl.formatMessage({
            id: 'Please enter only lowercase letters, numbers, or hyphens'
          })
        ),
      contractPenalties: Yup.string(),
      contractType: Yup.string().required(
        intl.formatMessage({
          id: 'Please select Contract type'
        })
      ),
      discountPercentage: Yup.number()
        .max(
          100,
          intl.formatMessage({
            id: 'Please enter a value less than or equal to 100'
          })
        )
        .min(
          0,
          intl.formatMessage({
            id: 'Please enter a value greater than or equal to 0'
          })
        ),
      discountNote: Yup.string(),
      downPaymentWhenReceivedOrder: Yup.number()
        .max(
          100,
          intl.formatMessage({
            id: 'Please enter a value less than or equal to 100'
          })
        )
        .min(
          0,
          intl.formatMessage({
            id: 'Please enter a value greater than or equal to 0'
          })
        ),
      fiscalRegimen: Yup.string(),
      fullPaymentBeforeShipping: Yup.boolean(),
      returnAllowed: Yup.boolean(),
      fromArtisanToClient: Yup.boolean(),
      otherSites: Yup.string()
        .min(
          2,
          intl.formatMessage({
            id: 'Please enter more than 2 characters'
          })
        )
        .required(
          intl.formatMessage({
            id: 'Please enter Other Sites'
          })
        ),
      email: Yup.string()
        .email(
          intl.formatMessage({
            id: 'Email should be a valid e-mail address'
          })
        )
        .required(
          intl.formatMessage({
            id: 'Please enter Email'
          })
        ),
      description: Yup.string()
        .min(
          2,
          intl.formatMessage({
            id: 'Please enter more than 2 characters'
          })
        )
        .required(
          intl.formatMessage({
            id: 'Please enter Description'
          })
        ),
      excludedCountries: Yup.array().of(Yup.string()),
      fixedPriceList: Yup.array().of(Yup.string()),
      website: Yup.string()
        .min(
          2,
          intl.formatMessage({
            id: 'Please enter more than 2 characters'
          })
        )
        .required(
          intl.formatMessage({
            id: 'Please enter Website'
          })
        )
        .matches(webUrlReg, {
          excludeEmptyString: true,
          message: intl.formatMessage({
            id: 'Correct the URL format'
          })
        }),
      country: Yup.string().required(
        intl.formatMessage({
          id: 'Please pick Country'
        })
      ),
      packagePicsBeforeShipping: Yup.boolean(),
      productPicsBeforeShipping: Yup.boolean(),
      state: Yup.string().required(
        intl.formatMessage({
          id: 'Please pick Region'
        })
      ),
      bespokeAvailable: Yup.boolean(),
      vetted: Yup.boolean(),
      buyer: Yup.string(),
      contracts: Yup.array().of(Yup.string()),
      catalogs: Yup.array().of(Yup.string())
    })
    const vendorName = this.getVendorName()
    const buyers = [
      'Giulia Galluzzo',
      'Chiara Anelli',
      'Lelio Zuccalà',
      'Mayra Benitez'
    ]

    return (
      <Fragment>
        <InnerPage
          heading={vendorName || 'Onboarding Flow'}
          vendor={get(this.props, 'vendorReducer.vendor')}
        >
          <Formik
            enableReinitialize
            initialValues={this.getInitValues()}
            validationSchema={schema}
            onSubmit={this.handleArtisanSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              setFieldValue,
              touched,
              initialValues
            }) => {
              useEffect(() => {
                if (!this.getId()) {
                  setFieldValue(
                    'slug',
                    slugify(values.artisanName, {
                      lower: true,
                      strict: true
                    })
                  )
                }
              }, [values.artisanName])

              return (
                <form
                  className={Style.wrap}
                  id="myForm"
                  onSubmit={handleSubmit}
                >
                  <PageNav className="rowWide" items={navItems} />
                  <div
                    className={cn(
                      'row rowWide justify-content-between',
                      Style.form
                    )}
                  >
                    <div className="col-md-6">
                      <div className={Style.inputWrap}>
                        <label className={Style.label} htmlFor="name">
                          <FormattedMessage id="Artisan Name" /> *
                        </label>
                        <input
                          className={Style.input}
                          type="text"
                          id="artisanName"
                          name="artisanName"
                          value={values.artisanName}
                          placeholder={intl.formatMessage({
                            id: 'Artisan Name'
                          })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />{' '}
                        {errors.artisanName && touched.artisanName && (
                          <div className={Style.error}>
                            {errors.artisanName}
                          </div>
                        )}
                      </div>
                      <div className={Style.inputWrap}>
                        <label className={Style.label} htmlFor="slug">
                          <FormattedMessage id="Slug" /> *
                        </label>
                        <input
                          className={Style.input}
                          type="text"
                          id="slug"
                          name="slug"
                          value={values.slug}
                          placeholder={intl.formatMessage({
                            id: 'Slug'
                          })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.slug && touched.slug && (
                          <div className={Style.error}>{errors.slug}</div>
                        )}
                      </div>
                      <div className={Style.inputWrap}>
                        <label className={Style.label} htmlFor="contactType">
                          <FormattedMessage id="Contract Type" /> *
                        </label>
                        <div className={Style.checkboxs}>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="fullyExclusive"
                              name="contractType"
                              value="fully exclusive"
                              onChange={() =>
                                setFieldValue('contractType', 'fully exclusive')
                              }
                              onBlur={handleBlur}
                              className="custom-control-input"
                              checked={
                                values.contractType === 'fully exclusive'
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="fullyExclusive"
                            >
                              <FormattedMessage id="fully exclusive" />
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="partialExclusive"
                              name="contractType"
                              value="partial exclusive"
                              onChange={() =>
                                setFieldValue(
                                  'contractType',
                                  'partial exclusive'
                                )
                              }
                              onBlur={handleBlur}
                              checked={
                                values.contractType === 'partial exclusive'
                              }
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="partialExclusive"
                            >
                              <FormattedMessage id="partial exclusive" />
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="notExclusive"
                              name="contractType"
                              value="not exclusive"
                              onChange={() =>
                                setFieldValue('contractType', 'not exclusive')
                              }
                              onBlur={handleBlur}
                              checked={values.contractType === 'not exclusive'}
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="notExclusive"
                            >
                              <FormattedMessage id="Not Exclusive" />
                            </label>
                          </div>
                        </div>
                        {errors.contractType && touched.contractType && (
                          <div className={Style.error}>
                            {errors.contractType}
                          </div>
                        )}
                      </div>
                      <div className={Style.inputWrap}>
                        <label className={Style.label} htmlFor="lText">
                          <FormattedMessage id="Other Sites" /> *
                        </label>
                        <textarea
                          className={Style.textarea}
                          type="textarea"
                          name="otherSites"
                          value={values.otherSites}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="lText"
                          rows="3"
                        />
                        {errors.otherSites && touched.otherSites && (
                          <div className={Style.error}>{errors.otherSites}</div>
                        )}
                      </div>
                      <div className={Style.inputWrap}>
                        <label className={Style.label} htmlFor="username">
                          <FormattedMessage id="Username" />
                          {' - '}
                          <FormattedMessage id="email" /> *
                        </label>
                        <input
                          className={Style.input}
                          type="email"
                          id="email"
                          name="email"
                          value={values.email}
                          placeholder={intl.formatMessage({ id: 'Username' })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email && (
                          <div className={Style.error}>{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={Style.inputWrap}>
                        <label className={Style.label} htmlFor="rText">
                          <FormattedMessage id="Description" /> *
                        </label>
                        <textarea
                          className={Style.textarea}
                          type="textarea"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="rText"
                          rows="5"
                        />
                        {errors.description && touched.description && (
                          <div className={Style.error}>
                            {errors.description}
                          </div>
                        )}
                      </div>
                      <div className={Style.inputWrap}>
                        <label className={Style.label} htmlFor="website">
                          <FormattedMessage id="Website" /> *
                        </label>
                        <input
                          className={Style.input}
                          type="text"
                          id="website"
                          name="website"
                          placeholder={intl.formatMessage({ id: 'Website' })}
                          value={values.website}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.website && touched.website && (
                          <div className={Style.error}>{errors.website}</div>
                        )}
                      </div>
                      <div className={Style.inputWrap}>
                        <label className={Style.label} htmlFor="area">
                          <FormattedMessage id="Area" /> *
                        </label>
                        <div className={Style.areaInput}>
                          <CountryDropdown
                            classes={cn(
                              'btn customInput',
                              Style.ddCountryRegion
                            )}
                            valueType="short"
                            type="text"
                            showDefaultOption
                            value={values.country}
                            onChange={(_val, event) => {
                              handleChange(event)
                            }}
                            onBlur={handleBlur}
                            name="country"
                            id="country"
                            disabled={values.country == 'IT'}
                          />
                          <RegionDropdown
                            classes={cn(
                              'btn customInput',
                              Style.ddCountryRegion
                            )}
                            countryValueType="short"
                            country={values.country}
                            value={values.state}
                            onChange={(_val, event) => {
                              handleChange(event)
                            }}
                            onBlur={handleBlur}
                            name="state"
                            id="state"
                          />
                        </div>
                        {errors.state && touched.state && (
                          <div className={Style.error}>{errors.state}</div>
                        )}
                        {errors.country && touched.country && (
                          <div className={Style.error}>{errors.country}</div>
                        )}
                      </div>
                      <div className={Style.inputWrap}>
                        <label className={Style.label} htmlFor="city">
                          <FormattedMessage id="City" /> *
                        </label>
                        <input
                          className={Style.input}
                          type="text"
                          id="city"
                          name="area"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={intl.formatMessage({ id: 'City' })}
                          value={values.area}
                        />
                        {errors.area && touched.area && (
                          <div className={Style.error}>{errors.area}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={cn(
                      'row rowWide justify-content-between',
                      Style.additionalFields
                    )}
                  >
                    <div className="row">
                      <Col xs={12} style={{ marginBottom: '4em' }}>
                        <FormattedMessage id="publishRequiredDisclaimer" />
                      </Col>
                      <Col xs={12} md={6}>
                        <div className={Style.inputWrap}>
                          <label className={Style.label} htmlFor="buyer">
                            <FormattedMessage id="buyer" /> *
                          </label>
                          <select
                            className={Style.select}
                            id="buyer"
                            name="buyer"
                            value={values.buyer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {(initialValues.buyer === '' ||
                              !initialValues.buyer) && <option></option>}
                            {buyers.map((buyer) => (
                              <option key={buyer} value={buyer}>
                                {buyer}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className={Style.inputWrap}>
                          <label
                            className={Style.label}
                            htmlFor="excludedCountries"
                          >
                            <FormattedMessage id="Excluded countries" />
                          </label>
                          <div className={Style.multipleSelect}>
                            <Input
                              multiple
                              id="excludedCountries"
                              name="excludedCountries"
                              type="select"
                              value={values.excludedCountries}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {COUNTRIES_LIST.map(({ code, name }) => (
                                <option key={code} value={code}>
                                  {name}
                                </option>
                              ))}
                            </Input>
                          </div>
                        </div>
                        <div className={Style.inputWrap}>
                          <label
                            className={Style.label}
                            htmlFor="discountPercentage"
                          >
                            <FormattedMessage id="discountPercentage" /> *
                          </label>
                          <CustomInput
                            className={Style.input}
                            id="discountPercentage"
                            max={100}
                            min={0}
                            decimalSign="."
                            name="discountPercentage"
                            placeholder={intl.formatMessage({
                              id: 'discountPercentage'
                            })}
                            type="number"
                            value={values.discountPercentage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.discountPercentage &&
                            touched.discountPercentage && (
                              <div className={Style.error}>
                                {errors.discountPercentage}
                              </div>
                            )}
                        </div>
                        <div className={Style.inputWrap}>
                          <label className={Style.label} htmlFor="discountNote">
                            <FormattedMessage id="discountNote" />
                          </label>
                          <textarea
                            className={Style.textarea}
                            type="textarea"
                            name="discountNote"
                            value={values.discountNote}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="lText"
                            rows="1"
                          />
                          {errors.discountNote && (
                            <div className={Style.error}>
                              {errors.discountNote}
                            </div>
                          )}
                        </div>
                        <div className={Style.inputWrap}>
                          <label
                            className={Style.label}
                            htmlFor="fiscalRegimen"
                          >
                            <FormattedMessage id="fiscalRegimen" />
                          </label>
                          <Input
                            id="fiscalRegimen"
                            name="fiscalRegimen"
                            type="select"
                            value={values.fiscalRegimen}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={Style.select}
                          >
                            <option value=""></option>
                            {FISCAL_REGIMEN.map((value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            ))}
                          </Input>
                        </div>
                        <div className={Style.inputWrap}>
                          <label
                            className={Style.label}
                            htmlFor="fixedPriceList"
                          >
                            <FormattedMessage id="fixedPriceList" />
                          </label>
                          <div
                            className={cn(
                              Style.multipleSelect,
                              Style.fixedPriceList
                            )}
                          >
                            <Input
                              multiple
                              id="fixedPriceList"
                              name="fixedPriceList"
                              type="select"
                              value={values.fixedPriceList}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {CURRENCIES_LIST.map((currency) => (
                                <option key={currency} value={currency}>
                                  {currency}
                                </option>
                              ))}
                            </Input>
                          </div>
                        </div>
                        <div className={Style.inputWrap}>
                          <label
                            className={Style.label}
                            htmlFor="downPaymentWhenReceivedOrder"
                          >
                            <FormattedMessage id="downPaymentWhenReceivedOrder" />{' '}
                            *
                          </label>
                          <input
                            className={Style.input}
                            id="downPaymentWhenReceivedOrder"
                            max={100}
                            min={0}
                            name="downPaymentWhenReceivedOrder"
                            placeholder={intl.formatMessage({
                              id: 'downPaymentWhenReceivedOrder'
                            })}
                            type="number"
                            value={
                              values.downPaymentWhenReceivedOrder !== undefined
                                ? values.downPaymentWhenReceivedOrder
                                : ''
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.downPaymentWhenReceivedOrder &&
                            touched.downPaymentWhenReceivedOrder && (
                              <div className={Style.error}>
                                {errors.downPaymentWhenReceivedOrder}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <YesNoRadioButtons
                          name="contractPenalties"
                          value={values.contractPenalties}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          options={['yes', 'no', 'reduced']}
                          requiredForPublish
                        />
                        <YesNoRadioButtons
                          name="bespokeAvailable"
                          value={values.bespokeAvailable}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                        />
                        <YesNoRadioButtons
                          name="vetted"
                          value={values.vetted}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                        />
                        <YesNoRadioButtons
                          name="returnAllowed"
                          value={values.returnAllowed}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          requiredForPublish
                        />
                        <YesNoRadioButtons
                          name="fullPaymentBeforeShipping"
                          value={values.fullPaymentBeforeShipping}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          requiredForPublish
                        />
                        <YesNoRadioButtons
                          name="packagePicsBeforeShipping"
                          value={values.packagePicsBeforeShipping}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          requiredForPublish
                        />
                        <YesNoRadioButtons
                          name="productPicsBeforeShipping"
                          value={values.productPicsBeforeShipping}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          requiredForPublish
                        />
                        <YesNoRadioButtons
                          name="fromArtisanToClient"
                          value={values.fromArtisanToClient}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                        />
                      </Col>
                    </div>
                    <div className="row" style={{ marginTop: '3em' }}>
                      <Col xs={12} md={6}>
                        <UploadElement
                          fieldValues={values.contracts}
                          fieldName="contracts"
                          fieldLabel={intl.formatMessage({
                            id: 'contracts'
                          })}
                          fieldPlaceholder={intl.formatMessage({
                            id: 'Upload Contract'
                          })}
                          setFieldValue={setFieldValue}
                          vendorCode={this.getId()}
                          requiredForPublish
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <UploadElement
                          fieldValues={values.catalogs}
                          fieldName="catalogs"
                          fieldLabel={intl.formatMessage({
                            id: 'catalogs'
                          })}
                          fieldPlaceholder={intl.formatMessage({
                            id: 'Upload Catalog'
                          })}
                          setFieldValue={setFieldValue}
                          vendorCode={this.getId()}
                        />
                      </Col>
                    </div>
                  </div>
                  {(this.getVendorLastEdit() ||
                    this.getVendorLastPublish()) && (
                    <div
                      className={cn(
                        'row rowWide justify-content-between',
                        Style.additionalFields
                      )}
                    >
                      <Col>
                        {this.getVendorLastEdit() && (
                          <div className={Style.date}>
                            <FormattedMessage
                              id="LastEdit"
                              values={{
                                date: this.getVendorLastEdit().date,
                                editor: this.getVendorLastEdit().editor,
                                label: (chunks) => (
                                  <span className={Style.dateLabel}>
                                    {chunks}
                                  </span>
                                )
                              }}
                            />
                          </div>
                        )}
                        {this.getVendorLastPublish() && (
                          <div className={Style.date}>
                            <FormattedMessage
                              id="LastPublish"
                              values={{
                                date: this.getVendorLastPublish().date,
                                label: (chunks) => (
                                  <span className={Style.dateLabel}>
                                    {chunks}
                                  </span>
                                )
                              }}
                            />
                          </div>
                        )}
                      </Col>
                    </div>
                  )}
                  <div
                    className={cn(
                      'row rowWide justify-content-between',
                      Style.additionalFields
                    )}
                  >
                    <Col xs={12} md={6}>
                      <button
                        className={cn('btn btn-primary', Style.button)}
                        disabled={!canUpdate}
                        type="submit"
                      >
                        <FormattedMessage id={buttonMessage} />
                      </button>
                    </Col>
                  </div>
                </form>
              )
            }}
          </Formik>
          <div className={Style.vendorManagement}>
            <h3>
              <FormattedMessage id="Vendor Management" />
            </h3>
            <Button onClick={this.activateCreditials}>
              <FormattedMessage id="Activate Credentials" />
            </Button>
            <Button onClick={this.disableCredentials}>
              <FormattedMessage id="Disable Credentials and Tools" />
            </Button>
          </div>
        </InnerPage>
        <Loading />
        <Footer absolute={false} />
      </Fragment>
    )
  }
}

YesNoRadioButtons.propTypes = {
  name: string,
  value: oneOfType([bool, string]) || undefined,
  setFieldValue: func,
  handleBlur: func,
  options: arrayOf(string),
  requiredForPublish: bool
}

UploadElement.propTypes = {
  fieldValues: arrayOf(string),
  fieldLabel: string,
  fieldPlaceholder: string,
  fieldName: string,
  setFieldValue: func,
  vendorCode: string,
  requiredForPublish: bool
}

Onboarding.propTypes = {
  match: shape({
    params: shape({ id: string })
  }),
  getVendor: func,
  intl: shape({
    formatMessage: func
  }),
  history: shape({
    push: func
  }),
  vendorReducer: shape({
    added: bool,
    vendor: shape({
      _id: string
    })
  }),
  collectionReducer: shape({
    collections: array
  }),
  GetCollection: func,
  GetVendor: func,
  canUpdate: bool,
  UpdateVendor: func,
  AddVendor: func,
  resetAdded: func,
  location: shape({
    pathname: string
  }),
  userType: string
}
const mapActions = {
  AddVendor,
  getVendor,
  GetCollection,
  GetVendor,
  UpdateVendor
}
const mapState = ({ authReducer, collectionReducer, vendorReducer }) => ({
  canUpdate: isAdmin(authReducer) || isManager(authReducer),
  collectionReducer,
  userType: getUserType(authReducer),
  vendorReducer
})

export default injectIntl(connect(mapState, mapActions)(Onboarding))
