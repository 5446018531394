import axios from 'axios/index'
import { set } from 'lodash'
import cookie from 'react-cookies'
import { mapError } from 'utils/errors'
import { getFormDataWithFiles } from 'utils/files'

import apiUrl from './apiUrl'

/**
 * @param {Object} config
 * @param {FileList} config.files
 * @param {String} config.orderNumber
 * @param {String} config.productId
 * @param {String} config.type - ORDER_IMAGE_TYPE from 'utils/constants'
 */
const addPhotos = async (config = {}) => {
  const { files = [], orderNumber = '', productId = '', type = '' } = config
  const apiPath = `${apiUrl}/api/order/${orderNumber}/product/${productId}/photo/${type}`
  const filesData = getFormDataWithFiles({
    files,
    propName: 'image'
  })

  try {
    const { data, status } = await axios.post(
      apiPath,
      {
        files: filesData
      },
      {
        headers: { token: cookie.load('artemest') }
      }
    )

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 *
 * @param {Object} config
 * @param {String} config.type
 * @param {String} config.url
 * @param {String} config.orderManagementId
 */
export const removePhoto = async (config = {}) => {
  const { type, url, orderManagementId } = config
  const apiPath = `${apiUrl}/api/order/management/${orderManagementId}/photo`

  try {
    const { data, status } = await axios.delete(apiPath, {
      headers: {
        token: cookie.load('artemest')
      },
      data: {
        type,
        imageUrl: url
      }
    })
    if (status === 200) {
      const { error, exception } = data
      if (error || exception) {
        throw new Error(error || exception)
      }
      return { data: true }
    }

    throw new Error(`Bad status update for removing image ${url}`)
  } catch (error) {
    return { error: mapError(error) }
  }
}
/**
 * @param {Object} config
 * @param {String} config.orderNumber
 */
const getOrderByNumber = async (config = {}) => {
  const { orderNumber } = config
  const apiPath = `${apiUrl}/api/order/${orderNumber}`

  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { order: data }
    }

    throw new Error(`Bad getting order ${orderNumber} data.`)
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {Number} config.limit
 * @param {Number} config.page
 * @param {String} config.vendorId
 * @param {Object} filters
 * @param {String} filters.status
 */
const getVendorOrders = async (config = {}) => {
  const { limit, page, vendorId, filters } = config

  try {
    const apiPath = `${apiUrl}/api/order/vendor/${vendorId}`
    const { data, status } = await axios.get(apiPath, {
      params: { limit, page, ...filters },
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { data }
    }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.shipmentNumber
 */
const setShipmentReady = async (config = {}) => {
  const { shipmentNumber } = config
  const apiPath = `${apiUrl}/api/order/shipment/${shipmentNumber}/ready`

  try {
    const res = await axios.put(apiPath, null, {
      headers: { token: cookie.load('artemest') }
    })
    console.log(res)
    // TODO: process the success result
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {Integer} config.orderId
 * @param {String} [config.orderNumber]
 * @param {String} [config.shipmentNumber]
 * @param {String} config.shipmentStatus
 * @param {String} [config.stockLocationId]
 * @param {Number|String} [config.trackingNumber] - orders[].shipments[].tracking ?
 * @param {Number|String} [config.variantId] - orders[].shipments[].manifest[0].variant_id
 */
const updateOrder = async (config = {}) => {
  const {
    orderId,
    orderNumber,
    shipmentNumber,
    shipmentStatus,
    stockLocationId,
    trackingNumber,
    variantId
  } = config
  const apiPath = `${apiUrl}/api/order/${orderId}`

  try {
    const { data, status } = await axios.post(
      apiPath,
      {
        order_data: {
          shipment_id: shipmentNumber,
          order_id: orderNumber,
          stock_location_id: stockLocationId,
          tracking_number: trackingNumber,
          variant_id: variantId
        },
        status: shipmentStatus
      },
      {
        headers: { token: cookie.load('artemest') }
      }
    )

    if (status === 200) {
      const { error, exception } = data

      if (error || exception) {
        throw new Error(error || exception)
      }

      return { shipment: data }
    }

    throw new Error(`Bad status update for the shipment ${shipmentNumber}.`)
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.shipmentNumber
 * @param {String} config.productId
 * @param {String} config.comments
 * @param {String} config.shippingDate
 */
const updateOrderShippingData = async (config = {}) => {
  const {
    shipmentNumber,
    productId,
    comments,
    shippingDate,
    imagesready,
    imagespacked,
    orderNumber,
    proformaFile,
    certificationFile,
    parcels
  } = config
  const apiPath = `${apiUrl}/api/order/${orderNumber}/shipment/${shipmentNumber}/product/${productId}/management`
  try {
    const payload = {
      expectedShippingAt: shippingDate,
      comment: comments,
      purchaseOrderFile: proformaFile,
      cardbCertification: certificationFile,
      parcels: parcels
    }
    if (imagesready) {
      set(payload, 'images.ready', imagesready)
    }
    if (imagespacked) {
      set(payload, 'images.packed', imagespacked)
    }
    const { data, status } = await axios.put(apiPath, payload, {
      headers: { token: cookie.load('artemest') }
    })
    if (status === 200) {
      const { error, exception } = data

      if (error || exception) {
        throw new Error(error || exception)
      }

      return { data }
    }

    throw new Error(`Bad status update for the shipment ${shipmentNumber}.`)
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} data
 * @param {FormData} data.formData
 * @param {String} data.productId
 */
const saveImages = async ({ formData } = {}) => {
  const apiPath = `${apiUrl}/api/order/save-multi-file-in-s3`

  try {
    const { data, status } = await axios.post(apiPath, formData, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      return { fileUrls: data.images || [] }
    }

    throw new Error(data)
  } catch (error) {
    return { error }
  }
}

const uploadFile = async (config = {}) => {
  const apiPath = `${apiUrl}/api/order/save-file-in-s3`
  const formData = new FormData()
  formData.append('file', config.files[0])
  try {
    const { data, status } = await axios.post(apiPath, formData, {
      headers: { token: cookie.load('artemest') }
    })
    if (status === 200) {
      return { data: { fileUrl: data || '' } }
    }
    throw new Error(data)
  } catch (error) {
    return { error }
  }
}

/**
 * @param {Object} config
 * @param {String} config.orderManagementId
 * @param {Object} config.type
 * @returns {Object}
 */
const proformaStatusService = async (config = {}) => {
  const { orderManagementId, type } = config
  const apiPath = `${apiUrl}/api/order/management/${orderManagementId}/status`
  try {
    const { data, status } = await axios.put(
      apiPath,
      { status: type },
      {
        headers: { token: cookie.load('artemest') }
      }
    )
    if (status === 200) {
      return { data }
    }
    throw new Error(data)
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.orderNumber
 * @param {Object} config.formData -- Order's Pickup Location and Time form data
 * @returns {Object}
 */
const updatePickupAddressAndTimeService = async (config = {}) => {
  const { orderNumber, vendorId, formData } = config
  const apiPath = `${apiUrl}/api/order/${orderNumber}/vendor/${vendorId}/management/pickup-address`

  try {
    const { data, status } = await axios.put(apiPath, formData, {
      headers: { token: cookie.load('artemest') }
    })

    if (status === 200) {
      const { error, exception } = data
      if (error || exception) {
        throw new Error(error || exception)
      }
      return { data }
    }
    throw new Error(
      `Bad staus pickup address and time update for order ${orderNumber}`
    )
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.orderNumber
 * @param {String} config.vendorId
 * @param {File} config.etdDocumentation
 * @returns {object}
 */
const attachETDDocumentation = async (config = {}) => {
  const { orderNumber, vendorId, etdDocumentation } = config

  const apiPath = `${apiUrl}/api/order/${orderNumber}/vendor/${vendorId}/documentation`
  const formData = new FormData()
  formData.append('documentation', etdDocumentation)

  try {
    const { data, status } = await axios.put(apiPath, formData, {
      headers: { token: cookie.load('artemest') }
    })

    if (status !== 200) {
      throw new Error(
        `Unable to attach ETD documentation to order ${orderNumber} for vendor ${vendorId}`
      )
    }
    return data
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.orderNumber
 * @param {String} config.vendorId
 * @returns {String}
 */
const getOrderDocumentation = async (config = {}) => {
  const { orderNumber, vendorId } = config
  const apiPath = `${apiUrl}/api/order/${orderNumber}/vendor/${vendorId}/documentation`
  try {
    const { data, status } = await axios.get(apiPath, {
      headers: { token: cookie.load('artemest') }
    })
    if (status !== 200) {
      throw new Error(
        `Unable to get documentation for order ${orderNumber} for vendor ${vendorId}`
      )
    }
    return { data }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.orderNumber
 * @param {String} config.vendorId
 * @returns {String}
 */
const deleteETDDocumentation = async (config = {}) => {
  const { orderNumber, vendorId } = config
  const apiPath = `${apiUrl}/api/order/${orderNumber}/vendor/${vendorId}/documentation`
  try {
    const response = await axios.delete(apiPath, {
      headers: { token: cookie.load('artemest') }
    })
    if (response.status !== 204) {
      throw new Error(
        `Unable to get ETD documentation for order ${orderNumber} for vendor ${vendorId}`
      )
    }
    return { success: true }
  } catch (error) {
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.orderNumber
 * @param {String} config.vendorId
 * @param {Number} config.invoiceTotal
 * @param {String} config.fedexBookingConfirmationNumber
 * @param {Boolean} config.addInsurance
 * @returns {String}
 */
const getShippingRates = async (config = {}) => {
  const {
    orderNumber,
    vendorId,
    invoiceTotal,
    fedexBookingConfirmationNumber,
    addInsurance
  } = config
  const apiPath = `${apiUrl}/api/order/${orderNumber}/vendor/${vendorId}/rates`
  try {
    const { data, status } = await axios.post(
      apiPath,
      { invoiceTotal, addInsurance, fedexBookingConfirmationNumber },
      {
        headers: { token: cookie.load('artemest') }
      }
    )
    if (status !== 200) {
      throw new Error(`Unable to get shipping rates for order ${orderNumber}`)
    }
    return { data }
  } catch (error) {
    console.log(error.response)
    return { error: mapError(error) }
  }
}

/**
 * @param {Object} config
 * @param {String} config.carrier
 * @param {String} config.carrier_id
 * @param {String} config.carrier_label
 * @param {String} config.currency
 * @param {String} config.delivery_days
 * @param {String} config.order_id
 * @param {Number} config.rate
 * @param {String} config.rate_id
 * @param {String} config.service,
 * @param {String} config.weight_range
 * @param {String} config.zone_name
 * @param {String} config.orderNumber
 * @param {String} config.vendorId
 * @param {Boolean} config.sendNotification
 * @param {Boolean} config.addInsurance
 * @param {String} config.fedexBookingConfirmationNumber
 */
const shipOrder = async (config = {}) => {
  const {
    carrier,
    carrier_id,
    carrier_label,
    currency,
    delivery_days,
    order_id,
    rate,
    rate_id,
    service,
    weight_range,
    zone_name,
    orderNumber,
    vendorId,
    addInsurance,
    sendNotification,
    invoiceTotal,
    fedexBookingConfirmationNumber,
    pickupTime
  } = config
  const apiPath = `${apiUrl}/api/order/${orderNumber}/vendor/${vendorId}/ship`
  try {
    const payload = {
      carrier,
      carrier_id,
      carrier_label,
      currency,
      delivery_days,
      order_id,
      rate,
      rate_id,
      service,
      weight_range,
      zone_name,
      addInsurance,
      sendNotification,
      invoiceTotal,
      fedexBookingConfirmationNumber,
      pickupTime
    }
    const { data, status } = await axios.post(apiPath, payload, {
      headers: { token: cookie.load('artemest') }
    })
    if (status !== 200) {
      throw new Error(`Unable to ship order ${orderNumber}`)
    }
    return { data }
  } catch (error) {
    console.log(error)
    return { error: mapError(error) }
  }
}

export {
  addPhotos,
  attachETDDocumentation,
  deleteETDDocumentation,
  getOrderByNumber,
  getOrderDocumentation,
  getShippingRates,
  getVendorOrders,
  proformaStatusService,
  saveImages,
  setShipmentReady,
  shipOrder,
  updateOrder,
  updateOrderShippingData,
  updatePickupAddressAndTimeService,
  uploadFile
}
