import { ProductCard } from 'components'
import { FloatList } from 'components/lists'
import React from 'react'

import { DEFAULT_PROPS, PROP_TYPES } from './config'

const ProductCardList = ({ items, noWrap }) => (
  <FloatList
    gutter={40}
    items={items.map(({ sku, ...item }) => ({
      content: <ProductCard {...item} />,
      id: sku
    }))}
    noWrap={noWrap}
  />
)

ProductCardList.defaultProps = DEFAULT_PROPS
ProductCardList.propTypes = PROP_TYPES

export default ProductCardList
