import { get } from 'lodash'
import { GetVendor, getVendor, SetPremium } from 'redux/actions/vendor-action'
import { initialSubscriptionTh } from 'redux/thunks/braintree'
import { getCollection } from 'utils/collections'

export const mapActions = {
  GetVendor,
  getVendor,
  SetPremium,
  initialSubscriptionTh
}

export const mapState = (
  {
    authReducer,
    collectionReducer,
    braintreeReducer: { plans: braintreePlans },
    vendorReducer: { vendor }
  },
  { intl }
) => {
  const collection = getCollection({ collectionReducer })
  return {
    vendor,
    intl,
    authReducer,
    braintreePlans,
    collectionId: get(collection, '_id', '')
  }
}
