/* eslint-disable no-unused-vars */
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React from 'react'
import BrandonGrotesque from 'static/fonts/BrandonGrotesque.ttf'
import FreightBigPro from 'static/fonts/FreightBigPro.ttf'

// Create styles
Font.register({
  family: 'FreightBigPro',
  src: FreightBigPro,
  fontStyle: 'oblique'
})
Font.register({
  family: 'BrandonGrotesque',
  src: BrandonGrotesque
})

Font.registerHyphenationCallback((word) => [word])

const styles = StyleSheet.create({
  page: {
    textAlign: 'center',
    paddingTop: '41mm',
    paddingBottom: '45mm'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  prequel: {
    fontFamily: 'FreightBigPro',
    fontSize: 25,
    marginHorizontal: '23mm',
    fontStyle: 'oblique'
  },
  main: {
    fontFamily: 'BrandonGrotesque',
    fontSize: 10,
    marginHorizontal: '30mm'
  },
  bio: {
    marginTop: 15
  },
  thankYou: {
    fontFamily: 'BrandonGrotesque',
    fontSize: 10,
    marginHorizontal: '23mm'
  }
})

const prequel =
  "An object is not just an object,\nbut it is time, it is passion,\nit is a piece of one's life."

const thankYou =
  'THANK YOU FOR SUPPORTING US IN SHARING THE BEAUTY AND UNIQUENESS OF ITALIAN CRAFTSMANSHIP.'

const ProductGiftPdf = ({
  productName,
  productId,
  vendorName,
  vendorBio,
  withSku
}) => {
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.prequel}>
            <Text>{prequel}</Text>
          </View>
          <View style={styles.main}>
            <Text>
              Enjoy our special{' '}
              {productName + (withSku ? ' (' + productId + ')' : '')} lovingly
              crafted for you by {vendorName}, embodying the essence of
              Artemest—the premier destination for Italian craftsmanship and
              design.
            </Text>
            <Text style={styles.bio}>{vendorBio}</Text>
          </View>
          <View style={styles.thankYou}>
            <Text>{thankYou}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

ProductGiftPdf.propTypes = {
  productName: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  vendorName: PropTypes.string.isRequired,
  vendorBio: PropTypes.string.isRequired,
  withSku: PropTypes.bool
}

export default ProductGiftPdf
