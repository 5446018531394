import cn from 'classnames'
import { Filters, PageNav, Spinner } from 'components'
import { InnerPage } from 'components/layout'
import { TitleIconList } from 'components/lists'
import Pagination from 'components/Pagination/Pagination'
import { RowToggle } from 'components/tableParts'
import { OrderDetails } from 'components/tables'
import { get } from 'lodash'
import { array, bool, func, number, oneOf, shape, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FiLifeBuoy } from 'react-icons/fi'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ORDER_FILTERS_DEFAULT } from 'utils/constants'
import { vendorNavItems } from 'utils/utils'

import { mapActions, mapState } from './config'
import st from './VendorOrders.module.css'

const VendorOrders = ({
  GetCollection,
  GetOrdersTh,
  intl,
  location: { pathname },
  match: { params },
  ordersFetching,
  currentPage,
  pages,
  orders,
  userType
}) => {
  const [filters, setFilters] = useState(ORDER_FILTERS_DEFAULT)
  const menuItems = [
    {
      icon: <FiLifeBuoy />,
      text: intl.formatMessage({ id: 'Need Help? Contact Us' })
    }
  ]
  const vendorId = get(params, 'vendor', '')
  const navItems = vendorNavItems({
    id: vendorId,
    intl,
    path: pathname,
    userType
  })

  useEffect(() => {
    GetCollection(vendorId)
    GetOrdersTh({ vendorId, filters })
  }, [vendorId, filters])

  const handlePageChange = (page) => {
    GetOrdersTh({ vendorId, page, filters })
  }
  const onFilterChange = (ev) => {
    const select = ev.target
    setFilters({ ...filters, [select.name]: select.value })
  }
  return (
    <InnerPage
      className={st.page}
      heading={intl.formatMessage({ id: 'Order Dashboard' })}
    >
      <TitleIconList className={st.tip} items={menuItems} />
      <PageNav className={st.pageNav} items={navItems} />
      <div className={st.table}>
        <Filters
          filters={[
            {
              name: 'status',
              options: [
                { label: intl.formatMessage({ id: 'All' }), value: '*' },
                {
                  label: intl.formatMessage({ id: 'Working' }),
                  value: 'ready'
                },
                {
                  label: intl.formatMessage({ id: 'Shipped' }),
                  value: 'shipped'
                },
                {
                  label: intl.formatMessage({ id: 'Returned' }),
                  value: 'returned'
                }
              ],
              placeholder: intl.formatMessage({ id: 'Status' })
            }
          ]}
          values={filters}
          onChange={onFilterChange}
          disabled={ordersFetching}
        />
        <Spinner show={ordersFetching}>
          {orders.length > 0 ? (
            <OrderDetails
              className={cn(st.ordersTable, {
                [st.ordersTableLoading]: ordersFetching
              })}
            >
              {orders.map(({ _id, cells, orderDetailPath }) => {
                return (
                  <RowToggle
                    cells={cells}
                    key={_id}
                    orderDetailPath={orderDetailPath}
                    toggledClassName={st.toggled}
                  />
                )
              })}
            </OrderDetails>
          ) : (
            <p
              className={cn(st.emptyState, {
                [st.emptyStateHidden]: ordersFetching
              })}
            >
              No orders
            </p>
          )}
        </Spinner>
      </div>
      <Pagination
        pages={pages}
        alignCenter
        current={currentPage}
        onChange={handlePageChange}
        disabled={ordersFetching}
      />
    </InnerPage>
  )
}

VendorOrders.propTypes = {
  collectionId: string,
  GetCollection: func,
  GetOrdersTh: func,
  intl: shape({
    formatMessage: func
  }),
  location: shape({
    pathname: string
  }),
  match: shape({
    params: shape({}),
    url: string
  }),
  ordersFetching: bool,
  shipments: array,
  userType: string,
  currentPage: number,
  pages: number,
  orders: array,
  filters: shape({
    status: oneOf(['*', 'shipped', 'ready'])
  }),
  SetFiltersTh: func
}
export default connect(mapState, mapActions)(injectIntl(VendorOrders))
