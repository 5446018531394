import cn from 'classnames'
import { Pagination, Spinner, Status } from 'components'
import { Button } from 'components/buttons'
import { InnerPage } from 'components/layout'
import {
  ProductHeadingList
  /* TODO: Remove when TitleIconContent is uncommented
    TitleIconContent
  */
} from 'components/lists'
import { OrdersTable } from 'components/tables'
import { array, bool, func, number, shape, string } from 'prop-types'
import React, { useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import { mapActions, mapState } from './config'
import st from './VendorDashboard.module.css'

const VendorDashboard = ({
  areOrdersReceived,
  currentPage,
  limit,
  GetOrdersTh,
  intl,
  match: {
    params: { vendor }
  },
  ordersFetching,
  ordersPageSet,
  ordersRows,
  pages,
  productsToDescribe,
  /* TODO: Remove when TitleIconContent is uncommented
  totalItems,
  */
  vendorDashboardInitTh
}) => {
  const history = useHistory()
  useEffect(() => {
    GetOrdersTh({
      limit,
      page: currentPage,
      vendorId: vendor
    })
  }, [currentPage, limit, vendor])

  useEffect(() => {
    vendorDashboardInitTh({ vendorId: vendor }, history)
  }, [vendor])

  const handlePageChange = (page) => {
    if (!ordersFetching) {
      ordersPageSet(page)
    }
  }

  const ordersRowsWithPath = ordersRows.map((or) => ({
    ...or,
    orderPath: `/order-list/${vendor}/${or.orderNumber}`
  }))

  return (
    <InnerPage
      className={st.page}
      heading={intl.formatMessage({ id: 'Vendor Dashboard' })}
    >
      <Row className="rowWide">
        <Col className={cn(st.col, st.main)} md={8} xs={12}>
          {/* TODO: Uncomment later
          <TitleIconContent
            className={st.totalCnt}
            cols={{ lg: 3, sm: 6, xs: 12 }}
            items={totalItems}
          /> */}
          {ordersRowsWithPath.length > 0 ? (
            <>
              <h2
                className="mod1"
                style={{
                  // TODO: Remove when TitleIconContent is uncommented
                  marginTop: 0
                }}
              >
                <FormattedMessage id="Recent orders" />
              </h2>
              <Spinner show={ordersFetching}>
                <OrdersTable
                  className={st.ordersTable}
                  rows={ordersRowsWithPath}
                />
                <Pagination
                  className={st.paginator}
                  current={currentPage}
                  pages={pages}
                  visiblePages={3}
                  onChange={handlePageChange}
                />
              </Spinner>
            </>
          ) : (
            <Status
              className={st.notFound}
              color="#14100a"
              fontSize={14}
              textAlign="left"
            >
              <FormattedMessage
                id={
                  areOrdersReceived ? 'No orders found' : 'Fetching orders...'
                }
              />
            </Status>
          )}
        </Col>
        <Col className={cn(st.col, st.sidebar)} md={4} tag="aside" xs={12}>
          <a href={`/vendors/${vendor}/catalog`}>
            <Button black className={st.buttonPropose}>
              <FormattedMessage id="View all products" />
            </Button>
          </a>

          <div className={st.sidebarCnt}>
            <h2 className="mod1 noMarginTop">
              <FormattedMessage id="Products to describe" />
            </h2>
            <ProductHeadingList
              className={st.productsToDescribe}
              cols={{ xs: 12 }}
              items={productsToDescribe}
            />
          </div>
        </Col>
      </Row>
    </InnerPage>
  )
}

VendorDashboard.propTypes = {
  areOrdersReceived: bool,
  currentPage: string,
  limit: number,
  GetOrdersTh: func,
  intl: shape({
    formatMessage: func
  }),
  match: shape({
    params: shape({
      vendor: string
    })
  }),
  ordersFetching: bool,
  ordersPageSet: func,
  ordersRows: array,
  pages: number,
  productsToDescribe: array,
  vendorDashboardInitTh: func
}

export default connect(mapState, mapActions)(injectIntl(VendorDashboard))
