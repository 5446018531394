import { get, noop } from 'lodash'
import { escape, sortVendorsByDate } from 'utils/utils'

import notify, { notifyPositions } from '../../utils/toast'
import {
  add,
  approve,
  approveById,
  changeStatus,
  getFilteredVendors,
  getVendorById,
  remove,
  removeImage,
  saveInstaHandle,
  savePromoteService,
  setPremium,
  update
} from '../service/vendor-service'
import {
  ADDED_VENDOR,
  APPROVE_VENDOR,
  CHANGE_ADD_STATUS,
  DELETE_URL,
  DELETE_VENDOR,
  GET_VENDOR,
  GET_VENDORS,
  SAVE_PROMOTE,
  SAVED_HANDLE,
  UPDATE_VENDOR,
  VENDOR_PREMIUM_ANALYTICS_ENABLE,
  VENDOR_PRODUCTS_GOT
} from './action-types'
import { AuthError } from './auth-action'
import { HideLoading, ShowLoading } from './loading-action'

const added = (added, vendor) => ({
  type: ADDED_VENDOR,
  added,
  vendor,
  delete: false
})

const updated = (updated) => ({
  type: UPDATE_VENDOR,
  added: false,
  updated
})

const getVendors = (vendors) => ({
  type: GET_VENDORS,
  vendors,
  delete: false
})

const getVendor = (vendor) => ({
  type: GET_VENDOR,
  vendor,
  delete: false
})

const deleteVendor = (deleted) => ({
  type: DELETE_VENDOR,
  delete: deleted
})

const approveVendor = (approved) => ({
  type: APPROVE_VENDOR,
  approved
})

const savePromote = ({ promotedImages, savedPromote }) => ({
  type: SAVE_PROMOTE,
  promotedImages,
  savedPromote
})

const AddVendor =
  (config = {}) =>
  (dispatch) => {
    const { data, onSuccess } = config
    data.approved = false
    dispatch(ShowLoading())
    add(data)
      .then((res) => {
        dispatch(HideLoading())
        dispatch(added(true, res.data))
        notify('success', 'Added successfully', notifyPositions.bottom.center)

        if (onSuccess) {
          onSuccess(res.data._id)
        }
      })
      .catch((err) => {
        dispatch(HideLoading())
        dispatch(added(false))
        let message = "The vendor hasn't been added"
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(AuthError(err.response.status))
          }
          if (err.response.status === 400) {
            message = "The vendor hasn't been added"
          }
          if (typeof err.response.data === 'string') {
            message = err.response.data
          } else if (err.response.data.error) {
            message = err.response.data.error
          }
        }
        notify('error', message, notifyPositions.bottom.center)
      })
  }

const UpdateVendor =
  (config = {}) =>
  (dispatch) => {
    const { id, data, editedFields, onSuccess = noop } = config

    data.approved = true
    data.editedFields = editedFields
    dispatch(ShowLoading())
    update(id, data)
      .then(() => {
        dispatch(HideLoading())
        dispatch(updated(true))
        onSuccess()
        notify('success', 'Updated successfully', notifyPositions.bottom.center)
      })
      .catch((err) => {
        dispatch(HideLoading())
        dispatch(updated(true))
        if (err.response && err.response.status) {
          if (err.response.status === 401) {
            dispatch(AuthError(err.response.status))
          }
        }
        notify(
          'error',
          "the vendor hasn't been updated",
          notifyPositions.bottom.center
        )
      })
  }

const ChangeVendorStatus =
  (config = {}) =>
  (dispatch) => {
    const { id, status, onSuccess = noop } = config
    dispatch(ShowLoading())
    changeStatus(id, status)
      .then(() => {
        dispatch(HideLoading())
        onSuccess()
        notify('success', 'Updated successfully', notifyPositions.bottom.center)
      })
      .catch((err) => {
        dispatch(HideLoading())
        if (err.response && err.response.status) {
          if (err.response.status === 401) {
            dispatch(AuthError(err.response.status))
          }
        }
        notify(
          'error',
          "the vendor hasn't been updated",
          notifyPositions.bottom.center
        )
      })
  }

const GetVendors =
  (page = 1, limit = 5, name) =>
  (dispatch) => {
    dispatch(ShowLoading())

    getFilteredVendors(page, limit, name)
      .then((res) => {
        const { data } = res
        const pendingVendors = get(data, 'pendingVendors.data', [])
        const recentlyApproved = get(data, 'recentlyApproved.data', [])
        const newData = {
          pendingVendors: {
            ...data.pendingVendors,
            data: [...pendingVendors]
              .sort(sortVendorsByDate)
              .map((v) => ({ ...v, _id: escape(v._id) }))
          },
          recentlyApproved: {
            ...data.recentlyApproved,
            data: [...recentlyApproved].sort(sortVendorsByDate)
          }
        }

        dispatch(getVendors(newData))
      })
      .catch((err) => {
        if (err.response && err.response.status) {
          dispatch(AuthError(err.response.status))
        }
        notify('error', `server error`, notifyPositions.bottom.center)
      })
      .finally(() => {
        dispatch(HideLoading())
      })
  }

/**
 * @param {Object} config
 * @param {Boolean} config.ifWithLoading
 * @param {String} config.vendorId
 * @param {Function} config.onSuccess
 */
const GetVendor =
  (config = {}) =>
  (dispatch) => {
    const { ifWithLoading = true, vendorId, onSuccess = noop } = config || {}
    if (ifWithLoading) {
      dispatch(ShowLoading())
    }

    getVendorById(vendorId).then(({ data, error }) => {
      if (data) {
        dispatch(getVendor(data))
        onSuccess(data)
      }

      if (error) {
        const { status } = error

        if (status === 401) {
          dispatch(AuthError(status))
        }

        dispatch(getVendor(null))
        notify('error', 'service error', notifyPositions.bottom.center)
      }

      if (ifWithLoading) {
        dispatch(HideLoading())
      }
    })
  }

const DeleteVendor = (id) => (dispatch) => {
  dispatch(ShowLoading())
  remove(id)
    .then(() => {
      dispatch(HideLoading())
      notify('success', 'delete successfully', notifyPositions.bottom.center)
      dispatch(deleteVendor(true))
    })
    .catch((err) => {
      dispatch(HideLoading())
      if (err.response && err.response.status) {
        if (err.response.status === 401) {
          dispatch(AuthError(err.response.status))
        }
      }
      dispatch(deleteVendor(false))
      notify('error', 'service error', notifyPositions.bottom.center)
    })
}

const ApproveVendor = (id, isApprove) => (dispatch) => {
  dispatch(ShowLoading())
  approve(id, isApprove)
    .then(() => {
      dispatch(HideLoading())
      const message = isApprove
        ? 'approved successfully'
        : 'disapproved successfully'
      notify('success', message, notifyPositions.bottom.center)
      dispatch(approveVendor(true))
    })
    .catch((err) => {
      dispatch(HideLoading())
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(approveVendor(false))
      notify('error', 'service error', notifyPositions.bottom.center)
    })
}

const ApproveVendorById = (id, isApprove) => (dispatch) => {
  dispatch(ShowLoading())
  approveById(id, isApprove)
    .then(() => {
      dispatch(HideLoading())
      const message = isApprove
        ? 'approved successfully'
        : 'disapproved successfully'
      notify('success', message, notifyPositions.bottom.center)
      dispatch(approveVendor(isApprove))
    })
    .catch((err) => {
      dispatch(HideLoading())
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(approveVendor(false))
      notify('error', 'service error', notifyPositions.bottom.center)
    })
}

const SaveVendorsBio = () => (dispatch) => {
  dispatch(ShowLoading())

  //TODO ROUTE

  // saveVendorsBio(bio).then(res => {
  //   notify('success', 'working', notifyPositions.bottom.center)
  //   dispatch(HideLoading())
  // }).catch(err => {
  //   dispatch(HideLoading())
  //   if (err.response && err.response.status) {
  //     if (err.response.status === 401) {
  //       dispatch(AuthError(err.response.status))
  //     }
  //   }
  //   notify('error', 'service error', notifyPositions.bottom.center)
  // })
}

const SavePromote = (id, data) => (dispatch) => {
  dispatch(ShowLoading())
  const bodyFormData = new FormData()
  const images = [...data.images]

  for (let i = 0; i < images.length; ++i)
    bodyFormData.append('images', images[i])

  savePromoteService(id, bodyFormData)
    .then((res) => {
      const { data, status } = res

      if (status === 200) {
        const { images } = data
        notify('success', 'saved successfully', notifyPositions.bottom.center)
        dispatch(
          savePromote({
            promotedImages: images || [],
            savedPromote: true
          })
        )
        dispatch(HideLoading())
      }
    })
    .catch((err) => {
      dispatch(HideLoading())
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(savePromote({ savedPromote: false }))
      notify('error', 'service error', notifyPositions.bottom.center)
    })
}

const SaveHandle = (id, instaName) => (dispatch) => {
  dispatch(ShowLoading())

  saveInstaHandle(id, instaName)
    .then((res) => {
      const { data, status } = res

      if (status === 200) {
        notify('success', 'saved successfully', notifyPositions.bottom.center)
        dispatch({
          type: SAVED_HANDLE,
          vendor: data
        })
      }
      dispatch(HideLoading())
    })
    .catch((err) => {
      dispatch(HideLoading())
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch(savePromote({ savedPromote: false }))
      notify('error', 'service error', notifyPositions.bottom.center)
    })
}

const SetPremium =
  (id, endDate, status, planId, onSuccess = noop) =>
  (dispatch) => {
    dispatch(ShowLoading())

    setPremium(id, endDate, status, planId)
      .then((res) => {
        const { data, status } = res

        if (status === 200 || status === 201) {
          notify('success', 'saved successfully', notifyPositions.bottom.center)
          dispatch({
            type: SAVED_HANDLE,
            vendor: data
          })
          onSuccess()
        }
        dispatch(HideLoading())
      })
      .catch((err) => {
        dispatch(HideLoading())
        if (err.response && err.response.status === 401) {
          dispatch(AuthError(err.response.status))
        }
        dispatch(savePromote({ savedPromote: false }))
        notify('error', 'service error', notifyPositions.bottom.center)
      })
  }

const DeletePic = (id, url) => (dispatch) => {
  dispatch(ShowLoading())

  removeImage(id, url)
    .then((res) => {
      const { data, status } = res

      if (status === 200) {
        notify('success', 'removed successfully', notifyPositions.bottom.center)
        dispatch({
          type: DELETE_URL,
          deletedUrl: true,
          images: data.images
        })
      }
      dispatch(HideLoading())
    })
    .catch((err) => {
      dispatch(HideLoading())
      if (err.response && err.response.status === 401) {
        dispatch(AuthError(err.response.status))
      }
      dispatch({
        type: DELETE_URL,
        deletedUrl: false
      })
      dispatch(savePromote({ savedPromote: false }))
      notify('error', 'service error', notifyPositions.bottom.center)
    })
}

const ChangeAddStatus = (status) => (dispatch) =>
  dispatch({
    type: CHANGE_ADD_STATUS,
    added: status
  })

const vendorPremiumAnalyticsEnable = (analyticsEnabled = false) => ({
  payload: { analyticsEnabled },
  type: VENDOR_PREMIUM_ANALYTICS_ENABLE
})

const vendorProductsGot = (products = []) => ({
  payload: { products },
  type: VENDOR_PRODUCTS_GOT
})

export {
  AddVendor,
  ApproveVendor,
  ApproveVendorById,
  ChangeAddStatus,
  ChangeVendorStatus,
  DeletePic,
  DeleteVendor,
  GetVendor,
  getVendor,
  GetVendors,
  SaveHandle,
  SavePromote,
  SaveVendorsBio,
  SetPremium,
  UpdateVendor,
  vendorPremiumAnalyticsEnable,
  vendorProductsGot
}
